import React, { useEffect } from 'react';
import * as api from '../../api';
const RenewalFormPt4 = ({ currentStep, processComplete, member }) => {
  useEffect(() => {
    if (processComplete) {
      api
        .sendRenewalEmailConfirmation(member)
        .then(api.sendRenewalEmailToChapter(member))
        .then(window.localStorage.removeItem('jci'));
    }
  }, []);
  if (currentStep !== 4) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 1 UI
  return (
    <div className="form-group">
      <h1>Confirmation</h1>
      <br></br>
      Your membership has been confirmed and payment has been set up
      successfully. <br></br>
      <p>You will receive an email with confirmation.</p>
      <p>We look forward to seeing you at our next event!</p>
    </div>
  );
};

export default RenewalFormPt4;
