import axios from 'axios';

export const setUpSession = (name) => {
  return axios
    .post(`/api/session`, {
      name,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const paymentPage = (values, processType) => {
  return axios
    .post(`/api/paymentPage`, {
      chapterID: values.chapter,
      authToken: values.accessToken,
      processType,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const completePayment = (
  ref,
  chapterForKey,
  authToken,
  payOption,
  membershipOption,
  discountCode
) => {
  return axios
    .post(`/api/completePayment`, {
      ref,
      chapterForKey,
      authToken,
      payOption,
      membershipOption,
      discountCode,
    })
    .then((resp) => resp)
    .catch(console.error);
};
export const createSubscription = (
  chapterForKey,
  mandate,
  authToken,
  payOption,
  membershipOption,
  discountCode
) => {
  return axios
    .post(`/api/createSubscription`, {
      chapterForKey,
      mandate,
      authToken,
      payOption,
      membershipOption,
      discountCode,
    })
    .then((resp) => resp)
    .catch(console.error);
};

export const writeValuesToDB = (member) => {
  return axios
    .post(`api/submitMember`, {
      member,
    })
    .then((resp) => resp)
    .catch(console.error);
};

export const getIncompleteMember = (redirect_id, authToken) => {
  return axios
    .post(`api/getIncompleteMember`, {
      params: {
        ID: redirect_id,
        authToken: authToken,
      },
    })
    .then((resp) => resp);
};

export const updateMemberOnCompletedSetup = (
  mandateID,
  subscriptionID,
  status,
  redirectID,
  memberId,
  paymentMethod,
  paymentDate,
  renewalDue
) => {
  return axios
    .post(`api/updateMemeberToCompleteInfo`, {
      mandateID,
      subscriptionID,
      status,
      redirectID,
      memberId,
      paymentMethod,
      paymentDate,
      renewalDue,
    })
    .then((resp) => resp)
    .catch(console.error);
};

export const updateMemberOnCompletedSetupRenewal = (
  mandateID,
  subscriptionID,
  status,
  redirectID,
  memberId,
  paymentMethod,
  paymentDate,
  renewalDue
) => {
  return axios
    .post(`api/updateMemeberToCompleteInfoRenewal`, {
      mandateID,
      subscriptionID,
      status,
      redirectID,
      memberId,
      paymentMethod,
      paymentDate,
      renewalDue,
    })
    .then((resp) => resp)
    .catch(console.error);
};

export const sendEmail = (newMember, mandateID, subscriptionID) => {
  return axios
    .post(`/api/sendEmail`, {
      newMember,
      mandateID,
      subscriptionID,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};
export const sendRenewalEmailConfirmation = (member) => {
  return axios
    .post(`/api/sendRenewalEmail`, {
      member,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};
export const sendAutoResponse = (email) => {
  return axios
    .post(`/api/sendAutoResponse`, { email })
    .then((resp) => resp.data)
    .catch(console.error);
};
export const sendRenewalEmailToChapter = (member) => {
  return axios
    .post(`/api/sendRenewalEmailToChapter`, {
      member,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const sendEmailWelcome = (newMember) => {
  return axios
    .post(`/api/sendWelcomeEmail`, {
      newMember,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const getDiscountCodes = () => {
  return axios
    .get(`api/getDiscountCodes`)
    .then((resp) => resp.data)
    .catch(console.error);
};

export const checkMandate = (mandateId, chapterId) => {
  return axios
    .post(`api/getMandateDetails`, { mandateId, chapterId })
    .then((res) => res.data)
    .catch(console.error);
};

export const getMemberFromDB = (membershipId) => {
  return axios
    .post(`api/getMember`, { membershipId })
    .then((res) => res.data)
    .catch(console.error);
};
export const getMemberFromDBByRedirectId = (redirectId) => {
  return axios
    .post(`api/getMemberByRedirectId`, { redirectId })
    .then((res) => res.data)
    .catch(console.error);
};

export const updateMemberForRenewal = (values) => {
  return axios
    .post(`api/updateMemberForRenewal`, { values })
    .then((res) => res.data)
    .catch(console.error);
};
