export const jciChapters = [
  {
    value: 'JCIE',
    label: 'JCI Edinburgh',
  },
  {
    value: 'JCIA',
    label: 'JCI Aberdeen',
  },
  {
    value: 'JCIG',
    label: 'JCI Glasgow',
  },
  {
    value: 'JCIALBA',
    label: 'JCI Alba',
  },
];

export const paymentOptions = [
  {
    value: 'Annual',
    label: 'Annual Subscription',
  },
  {
    value: 'Monthly',
    label: 'Monthly Direct Debit Subscription',
  },
];
export const membershipOptions = [
  {
    value: 'Standard',
    label: 'Standard Membership',
  },
  {
    value: 'Student',
    label: 'Student Membership',
  },
  {
    value: 'Online',
    label: 'Online Membership',
  },
];

export const getMembershipOptionBasedOnChapter = (chapterId) => {
  if (chapterId === 'JCIALBA') {
    return [
      {
        value: 'Online',
        label: 'Online Membership',
      },
    ];
  } else {
    return [
      {
        value: 'Standard',
        label: 'Standard Membership',
      },
      {
        value: 'Student',
        label: 'Student Membership',
      },
    ];
  }
};
