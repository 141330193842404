import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Online Membership',
    subheader: 'JCI Alba',
    price: '70',
    frequency: 'pa',
    monthlyCost: '35',
    monthlyfrequency: 'per month in 2 installments',
    description: [
      'Access to all online events',
      'Bespoke Training',
      'JCI Courses and Conferences online',
      'Access to a world wide network',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Full Membership',
    subheader: 'JCI Edinburgh, JCI Glasgow, JCI Aberdeen',
    price: '100',
    frequency: 'pa',
    monthlyCost: '35',
    monthlyfrequency: 'per month in 3 installments',
    description: [
      'Access to all events',
      'JCI.cc account',
      'Bespoke Training',
      'Opportunities to travel worldwide',
      'JCI Courses and Conferences',
      'Access to a world wide network',
      'Community Projects',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Student Membership',
    subheader: 'JCI Edinburgh, JCI Glasgow, JCI Aberdeen',
    price: '70',
    frequency: 'a year',
    monthlyCost: '35',
    monthlyfrequency: 'per month in 2 installments',
    description: [
      'Access to all events',
      'JCI.cc account',
      'Bespoke Training',
      'Opportunities to travel worldwide',
      'JCI Courses and Conferences',
      'Access to a world wide network',
      'Community Projects',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
];
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Membership Options
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={1} alignItems="flex-end">
          {tiers.map(
            (tier) => (
              // Enterprise card is full width at sm breakpoint
              console.log(tier),
              (
                <Grid item key={tier.title} md={4}>
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        >
                          £{tier.price}
                        </Typography>
                      </div>

                      <div className={classes.cardPricing}>
                        <Typography
                          component="h5"
                          variant="h5"
                          color="textPrimary"
                        >
                          £{tier.monthlyCost}
                        </Typography>
                        <Typography color="textSecondary">
                          /{tier.monthlyfrequency}
                        </Typography>
                      </div>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions>
                      {/* <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button> */}
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        href="/join"
                      >
                        Join Now
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
            )
          )}
        </Grid>
      </Container>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <Typography variant="h4" color="textPrimary" gutterBottom>
            Renew Your Membership
          </Typography>

          {/* {footer.description.map(item => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))} */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography>
            <p>
              To renew your membership please have your JCI membership number to
              hand - this should have been sent to you in the renewal email. If
              your membership was not previously on a Go Cardless subscription
              you will be redirected to our payment gateway to set up the
              mandate and returned to our system to process payment.
            </p>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              href="/renewal"
            >
              Renew Membership
            </Button>
          </Typography>
        </Grid>
        {/* <Box mt={5}>
          <MadeWithLove />
        </Box> */}
      </Container>
      {/* End footer */}

      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Terms & Conditions
            </Typography>

            {/* {footer.description.map(item => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))} */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography>
            <p>
              All payments are handled via our GoCardless system and you will be
              redirected during the process to confirm your bank details. If you
              cancel the process half way through or not return to our
              confirmation screen your membership is not activated.
            </p>
            <p>
              Your details and payment are handled by the Local Chapter that you
              choose and not with JCI Scotland. If you have any issues with the
              system please contact JCI Scotland via email at scotland@jci.cc
            </p>
            <p>
              All membership options are a 1 year subscription - you will be
              emailed to renew a month before your membership expires.
            </p>
            <p>
              For monthly subscription you have automatic access to local and
              national events, however until all monthly installments have been
              collected, funding opportunities for travel is not available
            </p>
          </Typography>
        </Grid>
        {/* <Box mt={5}>
          <MadeWithLove />
        </Box> */}
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
