import React from 'react'

const Confirmation = ({currentStep, subscriptionDetails}) =>{
      if (currentStep !== 3) { // Prop: The current step
        return null
      }
      // The markup for the Step 1 UI
      return(
        <div className="form-group">
          <h1>Confirmation</h1>
          <p>Your membership has been confirmed and payment has been set up successfully. </p>
            <p>You will receive an email with confirmation details and our membership pack.</p>
            <p>We look forward to seeing you at our next event!</p>
         
        </div>
      )
    }

  export default Confirmation