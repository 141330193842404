import * as Yup from 'yup';
import * as api from '../api';

const postcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
const membershipNumberRegex = /(JCI-[0-9])/;
export const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  houseNameNumber: Yup.string().required('Required'),
  addressLine1: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  addressLine2: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .nullable(),
  postCode: Yup.string()
    .matches(postcodeRegex, 'Not a valid postcode')
    .required('Required'),
});

export const SignupSchemaPage2 = Yup.object().shape({
  jobTitle: Yup.string()
    .min(2, 'Too short!')
    .max(30, 'Too Long!')
    .required('Required'),
  hearAboutUs: Yup.string().max(30, 'Too Long!'),
  chapter: Yup.string().required('Required'),
  membershipOption: Yup.string().test(
    'match',
    'Online Membership is only for JCI Alba at this time',
    function () {
      if (this.parent.membershipOption === 'Online') {
        return this.parent.chapter === 'JCIALBA';
      }
      if (this.parent.membershipOption === 'Student') {
        return this.parent.chapter !== 'JCIALBA';
      }
      if (this.parent.membershipOption === 'Standard') {
        return this.parent.chapter !== 'JCIALBA';
      }
      return true;
    }
  ),
  paymentOption: Yup.string().required('Required'),
  discountCode: Yup.string().test(
    'Discount Code',
    'Invalid discount Code',
    (value) => {
      if (value === null || value === '' || value === undefined) {
        return true;
      } else {
        return api.getDiscountCodes().then((res) => {
          const codes = res.map((code) => code.CODE_NAME);
          return codes.includes(value) ? true : false;
        });
      }
    }
  ),

  agreementTC: Yup.boolean().oneOf(
    [true],
    '*Must Accept Terms and Conditions*'
  ),
  agreementMail: Yup.boolean(),
});

export const memberIdSchema = Yup.object().shape({
  membershipNumber: Yup.string()
    .min(5, 'Too short!')
    .max(10, 'Too Long!')
    .required('Required')
    .matches(membershipNumberRegex, 'Not a valid Membership Number'),
});

export const renewalSchema = Yup.object().shape({
  membershipOption: Yup.string().required('Required'),
  paymentOption: Yup.string().required('Required'),
  discountCode: Yup.string().test(
    'Discount Code',
    'Invalid discount Code',
    (value) => {
      if (value === null || value === '' || value === undefined) {
        return true;
      } else {
        return api.getDiscountCodes().then((res) => {
          const codes = res.map((code) => code.CODE_NAME);
          return codes.includes(value) ? true : false;
        });
      }
    }
  ),
});
