const membershipTypes = [
  {
    paymentOption: 'Monthly',
    membershipOption: 'Standard',
    subscriptionName: 'Full Membership - Monthly Installments',
    amount: '3500',
    interval: 'monthly',
    count: '3',
  },
  {
    paymentOption: 'Monthly',
    membershipOption: 'Student',
    subscriptionName: 'Student Membership - Monthly Installments',
    amount: '3500',
    interval: 'monthly',
    count: '2',
  },
  {
    paymentOption: 'Annual',
    membershipOption: 'Student',
    subscriptionName: 'Student Membership',
    amount: '7000',
    interval: 'yearly',
    count: '1',
  },
  {
    paymentOption: 'Annual',
    membershipOption: 'Standard',
    subscriptionName: 'Full Membership',
    amount: '10000',
    interval: 'yearly',
    count: '1',
  },
  {
    paymentOption: 'Annual',
    membershipOption: 'Online',
    subscriptionName: 'Online Membership',
    amount: '7000',
    interval: 'yearly',
    count: '1',
  },
  {
    paymentOption: 'Monthly',
    membershipOption: 'Online',
    subscriptionName: 'Online Membership - Monthly Installments',
    amount: '3500',
    interval: 'monthly',
    count: '2',
  },
];

export const getMembershipData = (data) => {
  return membershipTypes.filter(
    (membership) =>
      membership.paymentOption === data.paymentOption &&
      membership.membershipOption === data.membershipOption
  );
};

const getDiscountPercentage = (discountCodes, discountCode) => {
  const discountObject = discountCodes.filter(
    (code) => code.CODE_NAME === discountCode
  );
  return discountObject[0].DISCOUNT_PERCENTAGE;
};
const calculateAmount = (amount, discountPercentage) => {
  const discountAmount = (amount / 100) * discountPercentage;
  return amount - discountAmount;
};
export const getAmountForMembership = (data, discountCodes) => {
  const membershipData = getMembershipData(data);
  if (data.discountCode) {
    const discountPercentage = getDiscountPercentage(
      discountCodes,
      data.discountCode
    );
    return parseFloat(
      calculateAmount(membershipData[0].amount, discountPercentage) / 100
    ).toFixed(2);
  }
  return parseFloat(membershipData[0].amount / 100).toFixed(2);
};
