import React, { Component } from 'react';
import './App.css';
import MasterForm from './components/MasterForm';
import PricingPage from './components/PricingPage';
import {
  BrowserRouter as Router,
  Switch,
  Route, // for later
} from 'react-router-dom';
import scotland from './img/scotland.png';
import glasgow from './img/glasgow.png';
import edinburgh from './img/edinburgh.png';
import aberdeen from './img/aberdeen.png';
import RenewalForm from './components/Renewal/MainRenewalForm';
class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <img src={scotland} className="App-logo" alt="logo" />
          <p>
            <img src={glasgow} className="App-logo1" alt="logo" />
            <img src={edinburgh} className="App-logo1" alt="logo" />
            <img src={aberdeen} className="App-logo1" alt="logo" />
          </p>
        </div>
        <Router>
          <Switch>
            <Route path="/" component={PricingPage} exact={true} />

            <Route path="/join" render={(props) => <MasterForm {...props} />} />
            <Route
              path="/renewal"
              render={(props) => <RenewalForm {...props} />}
            />
            {/* <Route path="/join/payment" render={(props) => <MasterForm {...props} />}/>
            <Route path="/join/confirmed" render={(props) => <MasterForm {...props} />}/> */}
            {/* <Route path="" render={<h1>TEST</h1>}/> */}
          </Switch>
        </Router>
        {/* <PricingPage /> */}
        {/* <MasterForm /> */}
      </div>
    );
  }
}

export default App;
