import * as api from '../api';
import moment, { parseTwoDigitYear } from 'moment';
export function triggerSubscriptionFromRedirectBackToApp(
  redirectId,
  authToken,
  handleNext,
  setProcessComplete,
  setMember
) {
  api.getMemberFromDBByRedirectId(redirectId).then((resp) => {
    if (resp.length > 0) {
      const member = resp[0];
      api
        .getDiscountCodes()
        .then((res) =>
          res.filter((code) => code.CODE_NAME === member.DISCOUNT_CODE)
        )
        .then((res) => {
          api
            .completePayment(
              redirectId,
              member.CHAPTER_TO_JOIN,
              authToken,
              member.PAYMENT_OPTION,
              member.MEMBERSHIP_OPTION,
              res
            )
            .then((res) => {
              //update incomplete user
              const memberId = `JCI-${member.ID}`;
              const paymentMethod = 'GC';
              const paymentDate = moment().format('DD/MM/YYYY');
              const renewalDue = moment().add(1, 'y').format('DD/MM/YYYY');
              const mandateID = res.data.subscriptions.links.mandate;
              const subscriptionID = res.data.subscriptions.id;
              const status = res.data.subscriptions.status;

              api
                .updateMemberOnCompletedSetup(
                  mandateID,
                  subscriptionID,
                  status,
                  redirectId,
                  memberId,
                  paymentMethod,
                  paymentDate,
                  renewalDue
                )
                .then((res) => {
                  api.getMemberFromDB(memberId).then((res) => {
                    setProcessComplete(true);
                    setMember(res[0]);
                    handleNext();
                  });
                });
            })
            .catch(console.error);
        });
    }
  });
}
