import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RenewalForm from './RenewalForm';
import RenewalFormPt1 from './RenewalFormPt1';
import RenewalFormPt2 from './RenewalFormPt2';
import RenewalFormPt3 from './RenewalFormPt3';
import RenewalFormPt4 from './RenewalFormPt4';
import queryString from 'query-string';
import { StepLabel, Step, Stepper } from '@material-ui/core';
import * as api from '../../api';
import { triggerSubscriptionFromRedirectBackToApp } from '../../helpers/triggerSubscription';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Membership Number',
    'Choose your membership',
    'Confirm Details',
    'Activating Payment',
    'Confirmation',
  ];
}

export default function StepperHeader(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [retrievedMember, setRetrievedMember] = useState();
  const [formValues, setFormValues] = useState();
  const [hasActiveMandate, setHasActiveMandate] = useState(false);
  const [discountCodes, setDiscountCodes] = useState();
  const [processComplete, setProcessComplete] = useState(false);
  const [hasValidMembershipId, setHasValidMembershipId] = useState(false);
  const parsed = queryString.parse(props.location.search);
  const [member, setMember] = useState();
  useEffect(() => {
    api.getDiscountCodes().then((res) => setDiscountCodes(res));
  }, []);
  useEffect(() => {
    if (parsed.redirect_flow_id !== undefined) {
      setActiveStep(3);
      const authToken = window.localStorage.getItem('jci');
      triggerSubscriptionFromRedirectBackToApp(
        parsed.redirect_flow_id,
        authToken,
        handleNext,
        setProcessComplete,
        setMember
      );
    }
  }, []);

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          {activeStep === 0 ? (
            <RenewalForm
              currentStep={activeStep}
              handleNext={handleNext}
              setRetrievedMember={setRetrievedMember}
              setFormValues={setFormValues}
              setHasActiveMandate={setHasActiveMandate}
              setHasValidMembershipId={setHasValidMembershipId}
            />
          ) : null}
          {activeStep === 1 ? (
            <RenewalFormPt1
              currentStep={activeStep}
              handleNext={handleNext}
              retrievedMember={retrievedMember}
              setFormValues={setFormValues}
            />
          ) : null}
          {activeStep === 2 ? (
            <RenewalFormPt2
              handleNext={handleNext}
              member={retrievedMember}
              hasMandate={hasActiveMandate}
              formValues={formValues}
              discountCodes={discountCodes}
              setProcessComplete={setProcessComplete}
              setMember={setMember}
              hasValidMembershipId={hasValidMembershipId}
            />
          ) : null}
          {activeStep === 3 ? (
            <RenewalFormPt3
              currentStep={activeStep}
              handleChange={handleNext}
            />
          ) : null}
          {activeStep === 4 ? (
            <RenewalFormPt4
              currentStep={activeStep}
              processComplete={processComplete}
              member={member}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
