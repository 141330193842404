import React from "react";
import { Formik, Form, Field } from "formik";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { TextField } from "formik-material-ui";
import {
  Typography,
  Grid,
  CssBaseline,
  Button,
  Avatar,
  Container
} from "@material-ui/core";
import { SignupSchema } from "../schemas";
import { formStyles } from "../styles";

import * as api from '../api'

const SignUpForm = ({ currentStep, handleChange, handleBack, storeValues }) => {
  const classes = formStyles();
  if (currentStep !== 0) {
    return <div />;
  }
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              houseNameNumber: "",
              addressLine1: "",
              addressLine2: null,
              postCode: ""
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              api.setUpSession(values.firstName).then(res => window.localStorage.setItem('jci', res)).then(storeValues(values))
            }}
          >
            {({ errors, touched }) => (
              <div>
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="firstName"
                        placeholder="First Name"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="lastName"
                        placeholder="Last Name"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="houseNameNumber"
                        placeholder="House Name/Number"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="addressLine1"
                        placeholder="Address Line 1"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="addressLine2"
                        placeholder="Address Line 2"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        <Field
                          name="postCode"
                          placeholder="Post Code"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                        />
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Button
                        disabled={currentStep === 0}
                        onClick={handleBack}
                        className={classes.submit}
                      >
                        Back
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default SignUpForm;
