import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Checkbox } from 'react-formik-ui';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { TextField } from 'formik-material-ui';
import { processTypes } from '../helpers/processTypes';
import {
  MenuItem,
  CssBaseline,
  Container,
  Avatar,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SignupSchemaPage2 } from '../schemas';
import { formStyles } from '../styles';
import { jciChapters, paymentOptions, membershipOptions } from '../formOptions';
import * as api from '../api';
const SignUpFormPt2 = ({
  currentStep,
  handleChange,
  handleBack,
  storeValues,
}) => {
  const classes = formStyles();
  const [selectedDate, handleDateChange] = useState(new Date());

  if (currentStep !== 1) {
    return <div />;
  }
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <Formik
            initialValues={{
              jobTitle: '',
              hearAboutUs: '',
              chapter: '',
              membershipOption: '',
              paymentOption: '',
              discountCode: '',
              agreementTC: false,
              agreementMail: false,
            }}
            validationSchema={SignupSchemaPage2}
            onSubmit={(values) => {
              const authToken = window.localStorage.getItem('jci');
              const newValues = {
                ...values,
                DOB: selectedDate,
                accessToken: authToken,
              };
              api.paymentPage(newValues, processTypes.JOIN).then((res) => {
                storeValues(
                  newValues,
                  res.redirect_flows.id,
                  res.redirect_flows.redirect_url
                );
              });
            }}
          >
            {({ errors, touched }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="jobTitle"
                      placeholder="Job Title"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="hearAboutUs"
                      placeholder="Where did you hear about JCI?"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <DatePicker
                        disableFuture
                        id="mui-pickers-date"
                        format="dd/MM/yyyy"
                        label="Date of birth"
                        views={["year", "month", "date"]}
                        value={selectedDate}
                        onChange={handleDateChange}
                        fullWidth
                        variant="outlined"
                      /> */}
                      <KeyboardDatePicker
                        disableFuture
                        margin="normal"
                        id="mui-pickers-date"
                        label="Date Of Birth"
                        format="dd/MM/yyyy"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        fullWidth
                        variant="outlined"
                        value={selectedDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="chapter"
                      label="JCI Chapter to join"
                      select
                      helperText="Please select which chapter you would like to join"
                      margin="normal"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {jciChapters.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="membershipOption"
                      label="Membership Option"
                      select
                      helperText="Please select Membership type"
                      margin="normal"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {membershipOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.membershipOption && touched.membershipOption ? (
                      <div>{errors.membershipOption}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="paymentOption"
                      label="Payment Option"
                      select
                      helperText="Please select Payment Option"
                      margin="normal"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {paymentOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="discountCode"
                      placeholder="Discount Code"
                      component={TextField}
                      fullWidth
                      variant="outlined"
                    />
                    {errors.discountCode && touched.discountCode ? (
                      <div>{errors.discountCode}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="agreementTC"
                      label="Agree to Terms and Conditions"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="agreementMail"
                      label="Agree to subscribe to mailing list and contact from the board"
                    />
                    {errors.agreementMail && touched.agreementMail ? (
                      <div>{errors.agreementMail}</div>
                    ) : null}
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Button
                      disabled={currentStep === 0}
                      onClick={handleBack}
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Back
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default SignUpFormPt2;
