import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function CircularIndeterminate({ currentStep }) {
  const classes = useStyles();
  if (currentStep !== 3) {
    // Prop: The current step
    return <div />;
  }
  return (
    <div>
      <CircularProgress className={classes.progress} />
      <h2>Activating membership subscription...</h2>
    </div>
  );
}
