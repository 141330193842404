import React from 'react';
import { Formik, Form, Field } from 'formik';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { TextField } from 'formik-material-ui';

import {
  MenuItem,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Avatar,
  Container,
} from '@material-ui/core';
import { memberIdSchema, renewalSchema } from '../../schemas';
import { formStyles } from '../../styles';
import {
  jciChapters,
  paymentOptions,
  membershipOptions,
} from '../../formOptions';

import * as api from '../../api';

const RenewalForm = ({
  currentStep,
  handleNext,
  setRetrievedMember,
  setFormValues,
  setHasActiveMandate,
  setHasValidMembershipId,
}) => {
  const classes = formStyles();

  if (currentStep !== 0) {
    return <div />;
  }

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter Membership Number
          </Typography>

          <Formik
            initialValues={{
              membershipNumber: '',
            }}
            validationSchema={memberIdSchema}
            onSubmit={(values) => {
              setFormValues(values);
              api
                .setUpSession(values.membershipID)
                .then((res) => {
                  window.localStorage.setItem('jci', res);
                })
                .then(
                  api.getMemberFromDB(values.membershipNumber).then((res) => {
                    if (res.length > 0) {
                      setHasValidMembershipId(true);
                      setRetrievedMember(res[0]);

                      if (res[0].GO_CARDLESS_MANDATE_ID) {
                        api
                          .checkMandate(
                            res[0].GO_CARDLESS_MANDATE_ID,
                            res[0].CHAPTER_TO_JOIN
                          )
                          .then((res) => {
                            if (res) {
                              const { mandates } = res || {};
                              if (mandates && mandates.status === 'active') {
                                //has active
                                setHasActiveMandate(true);
                                handleNext();
                              } else {
                                //no mandate thats active
                                handleNext();
                              }
                            } else {
                              //mandate not in the system
                              handleNext();
                            }
                          });
                      } else {
                        handleNext();
                      }
                    } else {
                      setHasValidMembershipId(false);
                      handleNext();
                      //no member in the system send to join page
                    }
                  })
                );
            }}
          >
            {({ errors, touched }) => (
              <div>
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="membershipNumber"
                        placeholder="Membership Number"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default RenewalForm;
