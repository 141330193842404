import React, { useEffect, useState } from 'react';
import {
  getAmountForMembership,
  getMembershipData,
} from '../../helpers/membershipAmountHelper';
import * as api from '../../api';
import { processTypes } from '../../helpers/processTypes';
import moment from 'moment';
import { formStyles } from '../../styles';
import { Button } from '@material-ui/core';
const container = {
  display: 'flex',
  flexDirection: 'column',
  margin: '40px',
};
const flexItem = {
  alignItem: 'center',
  justifyContent: 'center',
};

const RenewalFormPt2 = ({
  formValues,
  member,
  hasMandate,
  discountCodes,
  handleNext,
  setProcessComplete,
  setMember,
  hasValidMembershipId,
}) => {
  const classes = formStyles();
  const membershipData = getMembershipData(formValues);

  const label =
    membershipData[0].interval === 'monthly'
      ? 'months'
      : membershipData[0].interval === 'yearly'
      ? 'year'
      : null;
  const amount = getAmountForMembership(formValues, discountCodes);
  const amountString =
    label === 'months'
      ? ` paying ${membershipData[0].count} monthly installments of £${amount}`
      : ` costing £${amount}`;

  const [redirectDetails, setRedirectDetails] = useState();
  const authToken = window.localStorage.getItem('jci');
  useEffect(() => {
    if (!hasMandate && hasValidMembershipId) {
      const values = {
        chapter: member.CHAPTER_TO_JOIN,
        accessToken: authToken,
      };
      api.paymentPage(values, processTypes.RENEWAL).then((res) =>
        setRedirectDetails({
          redirectId: res.redirect_flows.id,
          redirectUrl: res.redirect_flows.redirect_url,
        })
      );
    }
  }, []);

  const submitHandler = () => {
    //no mandate
    //update member with payOption, membership type, discount code (code or null), redirect id, redirect
    if (!hasMandate) {
      api
        .updateMemberForRenewal({
          ...formValues,
          redirectId: redirectDetails.redirectId,
          membershipNumber: member.MEMBERSHIP_ID,
        })
        .then((res) => window.location.assign(redirectDetails.redirectUrl));
    } else {
      handleNext();
      const discount = discountCodes.filter(
        (code) => code.CODE_NAME === formValues.discountCode
      );
      const { membershipOption, paymentOption } = formValues;
      api
        .updateMemberForRenewal({
          ...formValues,
          redirectId: null,
          membershipNumber: member.MEMBERSHIP_ID,
        })
        .then(
          api
            .createSubscription(
              member.CHAPTER_TO_JOIN,
              member.GO_CARDLESS_MANDATE_ID,
              authToken,
              paymentOption,
              membershipOption,
              discount
            )
            .then((res) => {
              const memberId = `JCI-${member.ID}`;
              const paymentMethod = 'GC';
              const paymentDate = moment().format('DD/MM/YYYY');
              const renewalDue = moment().add(1, 'y').format('DD/MM/YYYY');
              const mandateID = res.data.subscriptions.links.mandate;
              const subscriptionID = res.data.subscriptions.id;
              const status = res.data.subscriptions.status;

              api
                .updateMemberOnCompletedSetupRenewal(
                  mandateID,
                  subscriptionID,
                  status,
                  null,
                  memberId,
                  paymentMethod,
                  paymentDate,
                  renewalDue
                )
                .then((res) => {
                  api.getMemberFromDB(memberId).then((res) => {
                    setProcessComplete(true);
                    setMember(res[0]);
                    handleNext();
                  });
                });
            })
        );
      //create subscription and update member in db
    }
    // trigger subscription with mandate id then update member with payOption, membership type, discount code, sub details confirm
  };
  if (!hasValidMembershipId) {
    return (
      <div style={container}>
        <div style={flexItem}>
          We dont seem to have your details on file with the membership number
          provided. Please go through our full sign up route so we can add you
          to our database
        </div>
        <br></br>
        <div style={flexItem}>
          <Button fullWidth variant="contained" color="primary" href="/join">
            Join Now
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div style={container}>
        <div style={flexItem}> Confirm Details</div>
        <br></br>
        {hasMandate ? (
          <div style={flexItem}>
            Hi {member.FIRST_NAME}
            <p>
              You wish to renew to a {formValues.membershipOption} membership
              {amountString}.
            </p>
            <p>
              Please confirm so we can process payment against the existing bank
              mandate
            </p>
          </div>
        ) : (
          <div style={flexItem}>
            Hi {member.FIRST_NAME},
            <p>
              You wish to renew to a {formValues.membershipOption} membership
              {amountString}. We do not have an active bank mandate on file so
              will need to redirect you to our Go Cardless system to activate
              this.
            </p>
            <p>
              Please confirm and we will redirect you to active the bank madate
              and submit payment
            </p>
          </div>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={submitHandler}
        >
          Confirm
        </Button>
      </div>

      {/* <button className={classes.submit} onClick={submitHandler}> */}
      {/* Confirm
      </button> */}
    </>
  );
};

export default RenewalFormPt2;
