import React from 'react';
import { Formik, Form, Field } from 'formik';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { TextField } from 'formik-material-ui';

import {
  MenuItem,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Avatar,
  Container,
} from '@material-ui/core';
import { renewalSchema } from '../../schemas';
import { formStyles } from '../../styles';
import {
  jciChapters,
  paymentOptions,
  membershipOptions,
  getMembershipOptionBasedOnChapter,
} from '../../formOptions';

import * as api from '../../api';
const container = {
  display: 'flex',
  flexDirection: 'column',
  margin: '40px',
};
const flexItem = {
  alignItem: 'center',
  justifyContent: 'center',
};
const RenewalFormPt1 = ({
  currentStep,
  handleNext,
  retrievedMember,
  setFormValues,
}) => {
  const classes = formStyles();

  if (currentStep !== 1) {
    return <div />;
  }
  if (retrievedMember === undefined || retrievedMember.length === 0) {
    return (
      <div style={container}>
        <div style={flexItem}>
          We dont seem to have your details on file with the membership number
          provided. Please go through our full sign up route so we can add you
          to our database
        </div>
        <br></br>
        <div style={flexItem}>
          <Button fullWidth variant="contained" color="primary" href="/join">
            Join Now
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Choose your membership
          </Typography>

          <Formik
            initialValues={{
              membershipOption: '',
              paymentOption: '',
              discountCode: '',
            }}
            validationSchema={renewalSchema}
            onSubmit={(values) => {
              setFormValues(values);
              handleNext();
            }}
          >
            {({ errors, touched }) => (
              <div>
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="membershipOption"
                        label="Membership Option"
                        select
                        helperText="Please select Membership type"
                        margin="normal"
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {getMembershipOptionBasedOnChapter(
                          retrievedMember.CHAPTER_TO_JOIN
                        ).map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="paymentOption"
                        label="Payment Option"
                        select
                        helperText="Please select Payment Option"
                        margin="normal"
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {paymentOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="discountCode"
                        placeholder="Discount Code"
                        component={TextField}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default RenewalFormPt1;
