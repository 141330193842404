import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignUpForm from './SignUpForm';
import SignUpFormPt2 from './SignUpFormPt2';
import PaymentPage from './PaymentPage';
import Confirmation from './Confirmation';
import queryString from 'query-string';
import moment from 'moment';
import { StepLabel, Step, Stepper } from '@material-ui/core';
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Sign Up Step 1', 'Sign Up Step 2', 'Payment Page', 'Confirmation'];
}

export default function StepperHeader(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const parsed = queryString.parse(props.location.search);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState([]);
  const [formValues, storeFormValues] = React.useState([]);
  const [discountCodes, setDiscountCodes] = React.useState();
  React.useEffect(() => {
    if (parsed.redirect_flow_id !== undefined) {
      const authToken = window.localStorage.getItem('jci');
      setActiveStep(2);
      api
        .getIncompleteMember(parsed.redirect_flow_id, authToken)
        .then((resp) => {
          if (resp.data.length !== 0) {
            api
              .getDiscountCodes()
              .then((res) =>
                res.filter(
                  (code) => code.CODE_NAME === resp.data[0].DISCOUNT_CODE
                )
              )
              .then((res) => {
                api
                  .completePayment(
                    parsed.redirect_flow_id,
                    resp.data[0].CHAPTER_TO_JOIN,
                    authToken,
                    resp.data[0].PAYMENT_OPTION,
                    resp.data[0].MEMBERSHIP_OPTION,
                    res
                  )
                  .then((res) => {
                    //update incomplete user
                    const memberId = `JCI-${resp.data[0].ID}`;
                    const paymentMethod = 'GC';
                    const paymentDate = moment().format('DD/MM/YYYY');
                    const renewalDue = moment()
                      .add(1, 'y')
                      .format('DD/MM/YYYY');
                    const mandateID = res.data.subscriptions.links.mandate;
                    const subscriptionID = res.data.subscriptions.id;
                    const status = res.data.subscriptions.status;

                    api
                      .updateMemberOnCompletedSetup(
                        mandateID,
                        subscriptionID,
                        status,
                        parsed.redirect_flow_id,
                        memberId,
                        paymentMethod,
                        paymentDate,
                        renewalDue
                      )
                      .then(setActiveStep(3))
                      .then(
                        api.sendEmail(resp.data[0], mandateID, subscriptionID)
                      )
                      .then(window.localStorage.removeItem('jci'))
                      .then(api.sendEmailWelcome(resp.data[0]));
                  });
              });
          } else {
            setActiveStep(3);
          }
        })
        //  setActiveStep(2)

        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    api.getDiscountCodes().then((res) => setDiscountCodes(res));
  }, []);
  function setFormValues(values) {
    formValues.push(values);
    handleNext();
  }

  function setRedirectIDAndValuesObject(values, id, url) {
    const newValueObjectWithRedirectID = { ...values, redirectID: id };
    setFormValues(newValueObjectWithRedirectID);
    storeValuesToDBAndRedirect(url);
  }
  function storeValuesToDBAndRedirect(url) {
    const valuesForDB = { ...formValues[0], ...formValues[1] };
    api.writeValuesToDB(valuesForDB).then(window.location.assign(url));
  }
  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          {activeStep === 0 ? (
            <SignUpForm
              currentStep={activeStep}
              handleChange={handleNext}
              handleBack={handleBack}
              storeValues={setFormValues}
            />
          ) : null}
          {activeStep === 1 ? (
            <SignUpFormPt2
              currentStep={activeStep}
              handleChange={handleNext}
              handleBack={handleBack}
              storeValues={setRedirectIDAndValuesObject}
            />
          ) : null}
          {activeStep === 2 ? (
            <PaymentPage
              currentStep={activeStep}
              handleChange={handleNext}
              handleBack={handleBack}
              id={parsed}
            />
          ) : null}
          {activeStep === 3 ? (
            <Confirmation
              currentStep={activeStep}
              subscriptionDetails={subscriptionDetails}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
